var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vs-popup',{attrs:{"classContent":"","title":_vm.$t('Add new customer'),"active":_vm.isShow},on:{"update:active":function($event){_vm.isShow=$event}}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[(_vm.existingCustomer != null)?_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-alert',{attrs:{"color":"warning"}},[_vm._v(" \""+_vm._s(_vm.existingCustomer.name)+": đang được quản lý bởi \" "),(_vm.existingCustomer.managerId != _vm.currentUser.userId)?_c('span',[_vm._v(_vm._s(_vm.existingCustomer.managerFullName))]):_c('span',[_vm._v(_vm._s(_vm.$t('You')))]),_vm._v("\" ")])],1)]):_vm._e(),(!_vm.isHasSalesChannel)?_c('div',{staticClass:"vx-row mb-5"},[_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"con-vs-alert con-vs-alert-warning",staticStyle:{"margin-top":"10px","height":"90px"}},[_c('h4',{staticClass:"titlex vs-alert--title"},[_vm._v("Thông báo")]),_c('div',{staticClass:"vs-alert"},[_vm._v(" Bạn chưa được cấu hình kênh bán hàng. Bạn không thể thêm khách hàng vui lòng liên hệ với Quản trị viên. ")])])])]):_vm._e(),_c('ValidationProvider',{attrs:{"name":_vm.$t('Customer type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('label',{staticClass:"vs-input--label",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('Customer type')))]),_c('v-select',{attrs:{"options":_vm.listCustomerType,"reduce":function (item) { return item.value; },"label":"text"},on:{"change":_vm.onCustomerTypeChange},model:{value:(_vm.customer.type),callback:function ($$v) {_vm.$set(_vm.customer, "type", $$v)},expression:"customer.type"}}),_c('span',{staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span span-text-validation"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('Customer code')}},[_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"Mã sẽ tự sinh nếu để trống","label":_vm.$t('Customer code')},model:{value:(_vm.customer.code),callback:function ($$v) {_vm.$set(_vm.customer, "code", $$v)},expression:"customer.code"}})],1)])]),_c('ValidationProvider',{attrs:{"name":_vm.customerNameLabel,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-input',{ref:"customerNameElement",staticClass:"w-full",attrs:{"type":"text","label":_vm.customerNameLabel,"placeholder":_vm.customerPlaceholder},model:{value:(_vm.customer.name),callback:function ($$v) {_vm.$set(_vm.customer, "name", $$v)},expression:"customer.name"}}),_c('span',{staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span span-text-validation"},[_vm._v(_vm._s(errors[0])+" ")])],1)])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":'Giới tính'}},[_c('label',{staticClass:"vs-input--label",attrs:{"for":""}},[_vm._v("Giới tính")]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('v-select',{attrs:{"options":_vm.listGender,"reduce":function (item) { return item.id; },"label":"text"},model:{value:(_vm.customer.gender),callback:function ($$v) {_vm.$set(_vm.customer, "gender", $$v)},expression:"customer.gender"}})],1)])]),(_vm.customer.type === _vm.customerType.company)?_c('ValidationProvider',{attrs:{"name":'Tên người liên hệ'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-input',{ref:"contactName",staticClass:"w-full",attrs:{"type":"text","label":'Tên người liên hệ',"placeholder":"Nhập tên người liên hệ"},model:{value:(_vm.customer.contactName),callback:function ($$v) {_vm.$set(_vm.customer, "contactName", $$v)},expression:"customer.contactName"}}),_c('span',{staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span span-text-validation"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":_vm.$t('Customer phonenumber')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-input',{ref:"customerPhoneNumber",staticClass:"w-full",attrs:{"label":_vm.customerPhoneNumberLabel,"placeholder":"Nhập số điện thoại"},on:{"blur":_vm.onPhoneNumberBlur},model:{value:(_vm.customer.phoneNumber),callback:function ($$v) {_vm.$set(_vm.customer, "phoneNumber", $$v)},expression:"customer.phoneNumber"}}),_c('span',{staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span span-text-validation"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,true)}),(_vm.customer.type === _vm.customerType.company)?_c('div',{staticClass:"mt-3"},[_c('label',{directives:[{name:"t",rawName:"v-t",value:('Vai trò người liên hệ'),expression:"'Vai trò người liên hệ'"}],staticClass:"vs-input--label",attrs:{"for":""}}),_c('CategorySelect',{attrs:{"type":_vm.categoryType.ContactRole,"placeholder":'Chọn vai trò người liên hệ.',"listSelected":_vm.selectedRole},on:{"select":_vm.onRoleSelected}})],1):_vm._e(),_c('ValidationProvider',{attrs:{"name":_vm.$t('Manager')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('label',{directives:[{name:"t",rawName:"v-t",value:('Manager'),expression:"'Manager'"}],staticClass:"vs-input--label",attrs:{"for":""}}),_c('UserSuggestion',{attrs:{"listSelected":_vm.selectedManager},on:{"selectedItemRemoved":_vm.onManagerRemoved,"select":_vm.onManagerSelected}}),_c('span',{staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span span-text-validation"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('Customer sales channel')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('label',{directives:[{name:"t",rawName:"v-t",value:('Customer sales channel'),expression:"'Customer sales channel'"}],staticClass:"vs-input--label",attrs:{"for":""}}),_c('CustomerGroupTreeDropdown',{ref:"salesChannelTree",model:{value:(_vm.customer.salesChannelId),callback:function ($$v) {_vm.$set(_vm.customer, "salesChannelId", $$v)},expression:"customer.salesChannelId"}}),_c('span',{staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span span-text-validation"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('Address')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('label',{directives:[{name:"t",rawName:"v-t",value:('Address'),expression:"'Address'"}],staticClass:"vs-input--label",attrs:{"for":""}}),_c('vs-textarea',{attrs:{"placeholder":"Nhập địa chỉ"},model:{value:(_vm.customer.address),callback:function ($$v) {_vm.$set(_vm.customer, "address", $$v)},expression:"customer.address"}}),_c('span',{staticClass:"con-text-validation span-text-validation-danger vs-input--text-validation-span span-text-validation"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,true)}),_c('ProvinceDistrictSelect',{staticClass:"mb-3",attrs:{"rules":"required","label":_vm.$t('Province/District'),"placeholder":'Chọn Tỉnh/TP - Quận/Huyện'},on:{"change":_vm.onDistrictChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return undefined}}],null,true),model:{value:(_vm.customer.districtId),callback:function ($$v) {_vm.$set(_vm.customer, "districtId", $$v)},expression:"customer.districtId"}}),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full text-right flex justify-end items-center"},[_c('button',{attrs:{"type":"submit","hidden":"true"}}),_c('span',[_c('vs-checkbox',{model:{value:(_vm.isCreateAnother),callback:function ($$v) {_vm.isCreateAnother=$$v},expression:"isCreateAnother"}},[_vm._v(_vm._s(_vm.$t('Create another')))])],1),_c('vs-button',{directives:[{name:"t",rawName:"v-t",value:('Save'),expression:"'Save'"}],staticClass:"mr-3 mb-2",attrs:{"id":"btnSave","disabled":invalid || _vm.existingCustomer != null || !_vm.isHasSalesChannel},on:{"click":_vm.save}}),_c('vs-button',{directives:[{name:"t",rawName:"v-t",value:('Cancel'),expression:"'Cancel'"}],staticClass:"mb-2",attrs:{"color":"warning","type":"flat"},on:{"click":_vm.closePopup}})],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }