

























import {Component, Vue} from "vue-property-decorator";
import UserSuggestion from "@/components/suggestion/user-suggestion/UserSuggestion.vue";
import {CustomerService} from "@/services/customer/CustomerService";
import {ActionResult} from "@/models/ActionResult";
import {Suggestion} from "@/models/Suggestion";

@Component({
    name: "CustomerUpdateManager",
    components: {
        UserSuggestion,
    },
})
export default class CustomerUpdateManager extends Vue {
    title: string = "";
    isShow = false;
    userId: string;
    fullName: string;
    customerIds: string[] = [];
    loading = false;

    service = new CustomerService();

    constructor() {
        super();
    }

    show(customerIds: string[]) {
        this.isShow = true;
        this.customerIds = customerIds;
    }

    accept() {
        if (!this.userId) {
            this.$vs.notify({
                color: "danger",
                title: "Thông báo",
                text: "Vui lòng chọn user",
            })
        }

        this.loading = true;
        this.service.updateManagers(this.customerIds, this.userId)
            .finally(() => {
                this.loading = false
            }).then((result: ActionResult<number>) => {
            this.$vs.notify({
                color: result.code <= 0 ? "danger" : "sucess",
                title: "Thông báo",
                text: result.message,
            })

            if(result.code > 0){
                this.$emit('savedSuccess', this.userId);
                this.isShow = false;
            }
        })
    }

    onUserSelected(value: Suggestion[]) {
        if (value) {
            this.userId = value[0].id;
        } else {
            this.userId = "";
        }
    }
}
