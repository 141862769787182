
























import {Component, Vue} from "vue-property-decorator";
import {CustomerService} from "@/services/customer/CustomerService";
import {ActionResult} from "@/models/ActionResult";
import {Suggestion} from "@/models/Suggestion";
import CustomerGroupTreeDropdown from "../../components/CustomerGroupTreeDropdown.vue";
import {TreeModel} from "../../components/tree/TreeModel";
import {CustomerGroupService} from "@/views/customer-group/CustomerGroupService";

@Component({
    name: "CustomerUpdateSalesChannel",
    components: {
        CustomerGroupTreeDropdown,
    },
})

export default class CustomerUpdateSalesChannel extends Vue {
    title: string = "";
    isShow = false;
    customerIds: string[] = [];
    loading = false;

    customerGroups: TreeModel[] = [];
    salesChannelId = '';
    service = new CustomerService();

    constructor() {
        super();
    }

    show(customerIds: string[]) {
        this.isShow = true;
        this.customerIds = customerIds;
        this.getCustomerGroup();
    }

    accept() {
        if (!this.salesChannelId) {
            this.$vs.notify({
                color: "danger",
                title: "Thông báo",
                text: "Vui lòng chọn kênh bán hàng",
            })
        }

        this.loading = true;
        this.service.updateSalesChannel(this.customerIds, this.salesChannelId)
            .finally(() => {
                this.loading = false
            }).then((result: ActionResult<number>) => {
            this.$vs.notify({
                color: result.code <= 0 ? "danger" : "sucess",
                title: "Thông báo",
                text: result.message,
            })

            if (result.code > 0) {
                this.$emit("savedSuccess");
                this.isShow = false;
            }
        })
    }

    onCustomerUpdated(value: Suggestion[]) {
    }

    private getCustomerGroup() {
        const customerGroupService = new CustomerGroupService();
        customerGroupService.search()
            .then((result: TreeModel[]) => {
                this.customerGroups = result;
            });
    }
}
